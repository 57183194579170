<template>
  <div>
    <Navbar />
    <nuxt class="space-navbar" />
    <PrivacyPolicy />

    <Footer />
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=UA-65084540-1"
    ></script>

    <script lang="js">
      window.dataLayer = window.dataLayer || []

      function gtag() {
        dataLayer.push(arguments)
      }
      const js = 'js'

      gtag(js, new Date())

      gtag('config', 'UA-65084540-1')
    </script>

    <!-- Global site tag (gtag.js) - Google Ads: 393528585 -->
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-393528585"
    ></script>
    <script>
      window.dataLayer = window.dataLayer || []
      function gtag() {
        dataLayer.push(arguments)
      }

      gtag(js, new Date())
      gtag('config', 'AW-393528585')
    </script>
    <!-- Facebook Pixel Code -->

    <script>
      !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments)
        }

        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'

        n.queue = []
        t = b.createElement(e)
        t.async = !0

        t.src = v
        s = b.getElementsByTagName(e)[0]

        s.parentNode.insertBefore(t, s)
      })(
        window,
        document,
        'script',

        'https://connect.facebook.net/en_US/fbevents.js'
      )

      fbq('init', '139076358174538')

      fbq('track', 'PageView')
    </script>

    <noscript
      ><img
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=139076358174538&ev=PageView&noscript=1"
    /></noscript>

    <!-- End Facebook Pixel Code -->
  </div>
</template>
<script>
export default {
  head() {
    // Definišemo kanonski link direktno
    const canonicalLink = {
      rel: 'canonical',
      href: `https://milsped.com${this.$route.path}/` // Osigurajte da je ovo vaša osnovna domena
    }

    // Vraćamo objekat head sa kanonskim linkom
    return {
      link: [canonicalLink]
    }
  }
}
</script>


<style>
.space-navbar {
  margin-top: 124px;
}

@media (max-width: 767px) {
  .space-navbar {
    margin-top: 150px; /* ili bilo koja vrednost koja ti odgovara */
  }
}

html {
  font-family: 'Khand', sans-serif;
  font-size: 16px;
  word-spacing: 1px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  letter-spacing: 1px;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

.md\:px-40 > * {
  max-width: 1200px;
}
</style>

<script>
import Navbar from '~/components/Navbar'
import Footer from '~/components/Footer'
import PrivacyPolicy from '~/components/PrivacyPolicy'

export default {
  components: {
    Navbar,
    Footer,
    PrivacyPolicy
    // CoronaBanner: () => import('~/components/CoronaBanner.vue')
  },
  data() {
    return {
      showCoronaBanner: false
    }
  }
  // mounted() {
  //   this.showCoronaBanner = !localStorage.getItem('seenCoronaBanner2')
  // }
}
</script>
